import { useMediaQuery } from 'react-responsive';

import regata from './regata.png';
import regata2 from './regata2.jpg';
import preco from './preco.png';
import flechaDir from './flechaDir.png';
import flechaEsq from './flechaEsq.png';
import './App.css';

const styles = {
  container: {
    maxWidth: '60%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  title: {
    padding: '0.5em',
    backgroundColor: '#ff2828',
  },
  preco: {
    height: '30%',
    width: 'auto',
  },
  regata: {
    height: 'auto',
    maxWidth: '80%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  images: isMobile => ({
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    alignItems: 'center',
  }),
  contentText: isMobile => ({
    fontSize: isMobile ? '1em' : '2em',
    padding: '1em',
  }),
  button: {
    padding: '1em 2em',
    borderRadius: '10px',
    backgroundColor: '#ff2828',
    color: 'white',
    margin: '1em',
  },
  flecha: {
    maxHeight: '1em',
    width: 'auto',
    padding: '0em 1em',
  },
};

function App() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  return (
    <div>
      <div className='App'>
        <header className='App-header'>
          <div style={styles.container}>
            <h1 style={styles.title}>COMPRE JÁ A SUA REGATA DO FLAMENGO!</h1>
            <div style={styles.images(isMobile)}>
              <img style={styles.regata} src={regata} className='product' alt='sleveless shirt' />
              <img style={styles.preco} src={preco} className='price' alt='price' />
            </div>
            <div style={styles.row}>
              <img className='animated' style={styles.flecha} src={flechaDir} />
              <a style={styles.button} href='https://fabioaromanini.com'>
                <b>COMPRAR A REGATA !!!</b>
              </a>
              <img className='animated' style={styles.flecha} src={flechaEsq} />
            </div>
          </div>
        </header>
      </div>
      <div className='content'>
        <h1 style={{ margin: '1em', textAlign: 'center', overflow: 'auto' }}>
          NÃO PERCA ESSA OPORTUNIDADE DE HONRAR O MELHOR TIME DA NAÇÃO!
        </h1>
        <div style={styles.images(isMobile)}>
          <img src={regata2} style={styles.regata} />
          <div style={styles.contentText(isMobile)}>
            <p>A única que te proporciona:</p>
            <ul>
              <li>Respeito</li>
              <li>Tecido dry fit, 100% a prova de suor</li>
              <li>Tinta ULTRAMAX© resiste até 5000 lavagens</li>
              <li>Diminui a tua chance de ser roubado*</li>
            </ul>

            <div style={styles.row}>
              <a
                style={{ ...styles.button, backgroundColor: 'white', color: 'black' }}
                href='https://fabioaromanini.com'
              >
                <b>COMPRAR A REGATA !!!</b>
              </a>
            </div>
          </div>
        </div>
        <div style={{ textAlign: 'center' }}>
          <p>* aumenta as tuas chances de ser abordado por viaturas policiais</p>
        </div>
      </div>
    </div>
  );
}

export default App;
